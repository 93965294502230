<template>
  <div class="container pt-4">
    <h2 class="bg-light py-3">Realização de Telemarketing</h2>
    <div class="text-left">
      <b-card>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label><b-icon-calendar /> Data Agenda</label>
            <div class="row">
              <div class="col-6 pr-1">
                <b-input
                  type="date"
                  style="padding: 3px"
                  v-model="filtros.periodo.dt_i"
                />
              </div>
              <div class="col-6 pl-1">
                <b-input
                  type="date"
                  style="padding: 3px"
                  v-model="filtros.periodo.dt_f"
                />
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    tipo: String,
  },
  mounted() {},
  data() {
    return {
      filtros: {
        periodo: {
          dt_i: "",
          dt_f: "",
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
